import React from "react"
import values from "../../images/about/Group 172.png"
import saveplanet from "../../images/about/Group 183.png"
import globe from "../../images/about/Group 188.png"
import StandardLayout from "../../layouts/standard"
import designIcon from "../../images/icons/design.svg"
import performanceIcon from "../../images/icons/performance.svg"
import serviceIcon from "../../images/icons/service.svg"
import communityIcon from "../../images/icons/community.svg"
import sustainabilityIcon from "../../images/icons/sustainability.svg"
import { Link } from "gatsby"
import about5 from "../../images/about/Group 60.png"
import about6 from "../../images/about/Group 61.png"

export default function WhoWeAre(props) {
  return (
    <StandardLayout title="Our Core Values">
      <div className="core-cont-1">
        <div className="brown-back"></div>
        <div className="text-core-cont-1">
          <div>
            Our
            <br />
            Core Values
          </div>
          <div>
            Our core values are based on the pillars of renewed Innovation,
            Integrity, Teamwork, Excellence and Sustainability.
          </div>
        </div>
        <div className="img-core-cont-1">
          <img src={values} width="100%" />
        </div>
      </div>

      <div className="pillars-cont">
        <div>Our 5 Pillars</div>
        <div className="container-list">
          <Pillar
            title="Design"
            text="We at Stylam focus around creative design that enhance your inner and outer space. Functional design is our key driving force. While changing the trends incorporated into latest designs, today we have 1200 different designs in more than 120 textures and surfaces. Each style is arranged in-house to provide a fineness look with perfect execution."
            logo={designIcon}
          />
          <Pillar
            title="Performance"
            text="Stylam’s with its state of the art manufacturing facilities are designed in such a way to perform perfectly in residential, business and public spaces. Our products are manufactured by using premium materials, so that design and functionality remains uncompromised even in theharshest environment. Awards that Recognize our achievements are witness to our growth."
            logo={performanceIcon}
          />
          <Pillar
            title="Service"
            text="Sound Design know how and with Robust technical support we are able to assist you in finding beautiful laminates that will enrich your space with the performance and durability. We ensure that out across the globe our delivery is the fastest and timely."
            logo={serviceIcon}
          />
          <Pillar
            title="Community"
            text="Stylam has been always been committed for taking involvement with our communities beyond borders. To create a global framework that follows our vision of a healthier and safer environment."
            logo={communityIcon}
          />
          <Pillar
            title="Sustainability"
            text="Environmentally friendly products with a low carbon footprint and manufacturing process that eliminate emissions is our goal for the future. We put environmental sustainability factors at the heart of all our processes and decision making."
            logo={sustainabilityIcon}
          />
        </div>
      </div>

      <div className="our-resp-cont">
        <div>Our Responsibilities</div>
        <div>
          <img src={saveplanet} width="100%" />
        </div>
      </div>
      <div className="our-resp-cont2">
        <img src={globe} />
        <span>
          Stylam emphasis on building a healthy and living environment for
          future generations to come. We focus on strategic policies that act
          morally and mindfully towards climate and its sustainability. Our work
          ethic extends beyond our products and our involvement with our
          community goes beyond borders to create a global framework of a
          healthier and safer environment. We support local and national non –
          profit organizations. As a concerned corporate, we trust it is our
          obligation to help the more fragile section of society through our
          supported CSR projects. Our products are environmental friendly, where
          we take corrective actions to certify with health and hygiene safety
          standards. NSF certificate reinforces our commitment to safety for our
          consumers and environment.
        </span>
      </div>

      <div className="morelink-cont">
        <div className="morelink-img">
          <div>
            <strong>
              <Link to="/about/who-are-we">About Us</Link>
            </strong>
          </div>

          <img src={about5} alt="" />
        </div>
        <div className="morelink-img">
          <div>
            <strong>
              <Link to="/about/infrastructure">Infrastructure</Link>
            </strong>
          </div>

          <img src={about6} alt="" />
        </div>
      </div>
    </StandardLayout>
  )
}

function Pillar({ title, text, logo }) {
  return (
    <div className="pillar-item">
      <div className="pillartitle">
        <div>{title}</div>
        <div>
          <img src={logo} />
        </div>
      </div>
      <div className="pillar-text">{text}</div>
    </div>
  )
}
